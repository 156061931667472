import SignalLike from 'api/types/SignalLike.ts';
import { useSignal } from '@preact/signals';
import { ReadonlySignal as FreshReadonlySignal } from '@preact/signals-core';

// deno-lint-ignore no-explicit-any
export type ReadonlySignal<T = any> = FreshReadonlySignal<T>;

// deno-lint-ignore no-explicit-any
type MaybeSignal<T = any> = T | SignalLike<T> | ReadonlySignal<T>;

export function isSignal<T>(value?: MaybeSignal<T>): value is SignalLike<T> | ReadonlySignal<T> {
    if (!(value && typeof value === 'object')) {
        return false;
    }

    return 'value' in value && 'peek' in value &&
        typeof value['subscribe'] === 'function';
}

export function isReadOnlySignal<T>(
    value: MaybeSignal<T> | undefined,
): value is ReadonlySignal<T> {
    return isSignal<T>(value) && ['value', 'v'].some((x) => {
        if (!(x in value)) {
            return false;
        }

        const desc = (Object.getOwnPropertyDescriptor(value, x) ??
            Object.getOwnPropertyDescriptor(
                Object.getPrototypeOf(value),
                x,
            ))!;

        return !(desc.writable || desc.set);
    });
}

export type Signalified<T extends MaybeSignal> = T extends (SignalLike | ReadonlySignal) ? T : SignalLike<T>;
export type WriteableSignalified<T extends MaybeSignal> = T extends (SignalLike | ReadonlySignal)
    ? SignalLike<T['value']>
    : SignalLike<T>;

export function Signalify<T>(
    value: MaybeSignal<T> | undefined,
    def?: T,
): SignalLike<T> {
    if (value === undefined && def === undefined) {
        throw new Error('Cannot signalify undefined without a default value.');
    }

    return isSignal<T>(value) ? value : useSignal(value ?? def!);
}

export type Unsignalified<T> = T extends (SignalLike | ReadonlySignal) ? T['value'] extends undefined ? T : T['value']
    : T;

export function Unsignalify<T>(value?: MaybeSignal<T>): T | undefined {
    return isSignal<T>(value) ? value.value : value;
}

export default MaybeSignal;
